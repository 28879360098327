<template>
  <nav aria-label="breadcrumb" class="breadcrumb-area">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
      <li class="breadcrumb-item"><router-link to="/mypage/index">マイページ</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">参加予定プログラム確認</li>
    </ol>
  </nav>
  <div class="container-fluid">
    <div class="row mypage-contents">
      <side-bar />
      <div class="mypage-contents_contents ml-sm-auto px-md-4 py-4">
        <h2>参加予定プログラム</h2>
        <div class="top--section latestWorks mb-3">
          <template v-for="schedule in allSchedules" :key="schedule.event.id">
            <div class="latestWorks-inner result-type" v-if="schedule.event.images && schedule.event.images.length > 0">
              <img v-if="schedule.event.images && schedule.event.images.length > 0" :src="schedule.event.images[0].url" width="100%">
              <div class="latestWorks-inner--textarea">
                <div class="companyName">
                  <span class="badge bg-primary" v-if="isWaiting(schedule.event_member) == 0">申込済</span>
                  <span class="badge bg-primary" v-else-if="isWaiting(schedule.event_member) == 1">キャンセル待ちあり</span>
                  <span v-else class="badge bg-danger">キャンセル待ち</span>
                  <span class="badge bg-primary" v-if="schedule.event.price_detail > 0">有料</span><span
                    class="badge bg-danger" v-else>無料</span>
                </div>
                <div class="workTitle">
                  <router-link :to="{ name: 'EventDetail', params: { path: schedule.event.path } }"
                    v-if="schedule.event">{{ schedule.event.title }}</router-link>
                </div>
                <ul class="description">
                  <li>
                    <p v-html="schedule.event.content.replace(/\n/g, '<br>')">
                    </p>
                  </li>
                  <li v-if="schedule.event.target_types"><img src="@/images/icon-user-black2.svg" alt="対象">{{
                    schedule.event.target_types[0]['name'] }}{{ schedule.event.target_types.length >= 2
                    ? '〜' + schedule.event.target_types[schedule.event.target_types.length - 1]['name'] : '' }}</li>
                  <li><img src="@/images/icon-salary-black.svg" alt="金額">{{ schedule.event.price_detail }}(税込)</li>
                  <li v-if="schedule"><img src="@/images/icon-star-black2.svg" alt="開催期間">
                    <template v-if="schedule.start_at === schedule.end_at">
                      {{  toDateSlash(new Date(schedule.start_at)) }}
                    </template>  
                    <template v-else>
                      {{
                        toDateSlash(new Date(schedule.start_at)) }}{{ schedule.end_at ? '〜' + toDateSlash(new Date(schedule.end_at))
                        : ''
                        }}
                    </template>
                  </li>
                </ul>
                <div class="btn-area mb-0">
                  <router-link v-if="schedule"
                    :to="{ path: '/mypage/event_detail', query: { id: schedule.event.id, code: schedule.event_code } }"
                    class="btn btn-apply">詳細を確認</router-link>
                </div>
              </div>
            </div>
            <div class="latestWorks-inner result-type no-image" v-else>
              <div class="latestWorks-inner--textarea">
                <div class="companyName">
                  <span class="badge bg-primary" v-if="isWaiting(schedule.event_member) == 0">申込済</span>
                  <span class="badge bg-primary" v-else-if="isWaiting(schedule.event_member) == 1">キャンセル待ちあり</span>
                  <span v-else class="badge bg-danger">キャンセル待ち</span>
                  <span class="badge bg-primary" v-if="schedule.event.price_detail > 0">有料</span><span
                    class="badge bg-danger" v-else>無料</span>
                </div>
                <div class="workTitle">
                  <router-link :to="{ name: 'EventDetail', params: { path: schedule.event.path } }"
                    v-if="schedule.event">{{ schedule.event.title }}</router-link>
                </div>
                <ul class="description">
                  <li>
                    <p v-html="schedule.event.content.replace(/\n/g, '<br>')">
                    </p>
                  </li>
                  <li v-if="schedule.event.target_types"><img src="@/images/icon-user-black2.svg" alt="対象">{{
                    schedule.event.target_types[0]['name'] }}{{ schedule.event.target_types.length >= 2
                    ? '〜' + schedule.event.target_types[schedule.event.target_types.length - 1]['name'] : '' }}</li>
                  <li><img src="@/images/icon-salary-black.svg" alt="金額">{{ schedule.event.price_detail }}(税込)</li>
                  <li v-if="schedule"><img src="@/images/icon-star-black2.svg" alt="開催期間">
                    <template v-if="schedule.start_at === schedule.end_at">
                      {{ toDateSlash(new Date(schedule.start_at)) }}
                    </template>  
                    <template v-else>
                      {{
                        toDateSlash(new Date(schedule.start_at)) }}{{ schedule.end_at ? '〜' +  toDateSlash(new Date(schedule.end_at))
                        : ''
                        }}
                    </template>  
                  </li>
                </ul>
                <div class="btn-area mb-0">
                  <router-link v-if="schedule"
                    :to="{ path: '/mypage/event_detail', query: { id: schedule.event.id, code: schedule.event_code } }"
                    class="btn btn-apply">詳細を確認</router-link>
                </div>
              </div>
            </div>
          </template>
        </div>
        <pagination v-if="paginate" class="pagination justify-content-center" v-model="paginate.currentPage"
          :page-range="3" :margin-pages="2" :page-count="paginate.lastPage" :records="paginate.total"
          :per-page="paginate.perPage" :click-handler="paginata" :prev-text="prev" :next-text="next" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import SideBar from '@/components/Layouts/SideBar.vue'
import { API } from '../../api'
import { handleError, onMounted, ref } from 'vue'
import { stringToDateSlash, toDateSlash } from '@/js/util'
import { useMemberStore } from '@/stores/member';
import { handleErrors } from '@/config/handleErrors';
import { useLoadingStore } from '@/stores/loading'
import { TPaginate } from '@/types/search'
import Pagination from 'vuejs-paginate-next'

const allSchedules = ref<any>([])
const memberStore = useMemberStore()
const meta: any = ref({})

const paginate = ref<TPaginate>()
const prev = `<span aria-hidden="true"><i class="bi bi-chevron-double-left"></i></span>
              <span class="sr-only">Previous</span>`
const next = `<span aria-hidden="true"><i class="bi bi-chevron-double-right"></i></span>
              <span class="sr-only">Next</span>`

onMounted(async () => {
  useLoadingStore().start()
  try{
    const {
      allSchedules: fetchAllSchedules,
      paginate: fetchPaginate,
    }
      = await TPaginate.page({
        id: memberStore.id,
        page: 1,
      })
    allSchedules.value = fetchAllSchedules
    paginate.value = fetchPaginate
  } catch(e: any) {
    handleErrors(e)
  } finally {
    useLoadingStore().stop()
  
  }
})

function isWaiting(eventMember: any[]): number {
  let count = 0;
  eventMember.map(data => {
    if (data.is_waiting) {
      count++
    }
  })
  if (count == eventMember.length) {
    return 2
  } else if (count >= 1) {
    return 1
  }
  return 0
}

const paginata = async (page?: number) => {
  useLoadingStore().start()
  try {
    const {
      allSchedules: fetchAllSchedules,
      paginate: fetchPaginate,
    }
      = await TPaginate.page({
        id: memberStore.id,
        page: page,
      })
    allSchedules.value = fetchAllSchedules
    paginate.value = fetchPaginate
  } catch (e: any) {
    handleErrors(e)
  } finally {
    useLoadingStore().stop()
  }
}

</script>