<template>
  <nav aria-label="breadcrumb" class="breadcrumb-area">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
        <li class="breadcrumb-item active" aria-current="page">メンバー登録</li>
    </ol>
  </nav>
  <div class="container">
    <h1>メンバー登録</h1>
    <div class="entry-form-inner">
      <div class="mb-3 row">
        <label for="inputEmail" class="col-sm-4 col-form-label">メールアドレス&nbsp;<span
            class="badge bg-danger">必須</span></label>
        <div class="col-sm-8">
          <Field type="email" name="mailAddress" class="form-control" id="inputEmail" placeholder="name@example.com"
            v-model="mailAddress" />
          <div class="text-danger form-error">{{ errors.mailAddress }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4"></div>
        <div class="col-sm-8 row">
          <div class="form-check ps-3">
            <input class="form-check-input check" type="checkbox" :value="true" v-model="terms" id="kiyakuCheck">
            <label class="form-check-label" for="kiyakuCheck">
              <router-link to="/static/rule" target="_blank">利用規約</router-link>に同意します
            </label>
          </div>
          <div class="form-check ps-3">
            <input class="form-check-input check" type="checkbox" :value="true" v-model="privacy" id="privacyCheck">
            <label class="form-check-label" for="privacyCheck">
              <router-link to="/static/privacy" target="_blank">プライバシーポリシー</router-link>に同意します
            </label>
          </div>
          <div class="pl-3 pe-0 mt-3 font-sm">
            ＜登録画面では＞<br>
            保護者・成人の方をご登録いただき<br>お子さまは下部の「子ども追加」ボタンからご登録ください。
            </div>
          <div class="pl-3 pe-0 mt-3 font-sm">
              ＜ドメイン指定受信設定について＞<br>
              携帯電話、スマートフォンの設定で迷惑メール対策としてドメイン指定受信設定をされている場合に、
              弊社「{{ domain }}」からのメールを受信できない場合がございます。<br>
              弊社ドメイン「{{ domain }}」からのメールが受信できるように設定してください。
            </div>
        </div>
      </div>
      <div class="btn-area">
        <button class="btn btn-apply" type="button" id="btn_form_submit" :disabled="!canRegister()"
          @click="handleRegister()">登録</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { configure, useForm, useField, Field } from 'vee-validate';
import { ref } from 'vue'
import * as yup from 'yup'
import { useRouter } from 'vue-router'
import { useLoadingStore } from '@/stores/loading'
import { API } from '../../api'
import { handleErrors } from '@/config/handleErrors';

const router = useRouter()
const domain = 'tokyoymca.org'

configure({
  validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
  validateOnChange: false, // controls if `change` events should trigger validation with `handleChange` handler
  validateOnInput: false, // controls if `input` events should trigger validation with `handleChange` handler
  validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
})

const schema = yup.object({
  mailAddress: yup.string().required('メールアドレスは必須項目です').email('メールアドレスの形式で入力してください'),
})
const { validate, errors, values } = useForm({ validationSchema: schema })
const { value: mailAddress } = useField<string>('mailAddress')
const terms = ref(false)
const privacy = ref(false)

const canRegister = () => {
  if (terms.value && privacy.value && mailAddress.value) return true
}

const handleRegister = async () => {
  const valid = await validate()
  if (!valid.valid) return
  useLoadingStore().start()
  API.post('/api/entry/index', { mail_address: mailAddress.value })
    .then(res => {
      useLoadingStore().stop()
      if (res.data.status === 'success') {
        router.push('/entry/mail')
      } else {
        console.error('送信失敗')
      }
    }).catch(e => {
      handleErrors(e)
    })
}
</script>
