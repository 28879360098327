<template>
  <nav aria-label="breadcrumb" class="breadcrumb-area">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
      <li class="breadcrumb-item"><router-link to="/mypage/index">マイページ</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">参加済みプログラム確認</li>
    </ol>
  </nav>
  <div class="container-fluid">
    <div class="row mypage-contents">
      <side-bar />
      <div class="mypage-contents_contents ml-sm-auto px-md-4 py-4">
        <h2>参加済みプログラム確認</h2>
        <div class="entry-form-inner">
          <h4>申込み内容</h4>
          <!-- <p class="mt-3">説明文テキスト。説明文テキスト。説明文テキスト。</p> -->
          <div class="table-wrap">
            <table class="normal mgT20" cellspacing="0">
              <tbody>
                <tr>
                  <th class="tblBg03 tblTit">プログラムコード</th>
                  <td v-if="schedules">{{ schedules.eventCode }}</td>
                </tr>
                <tr>
                  <th class="tblBg03">プログラム名</th>
                  <td>{{ event?.title }}</td>
                </tr>
                <tr>
                  <th class="tblBg03">クラス開始日</th>
                  <td v-if="schedules">{{ toDateSlash(schedules.startAt) }}</td>
                </tr>
                <tr>
                  <th class="tblBg03">基本費用</th>
                  <td>{{ event?.priceDetail }}円</td>
                </tr>
                <!-- <tr>
							<th class="tblBg03">スキー教室参加に伴い、同意書に捺印して頂く必要があります。</th>
							<td>
								承しました
							</td>
						</tr> -->
              </tbody>
            </table>
          </div>
          <div class="table-wrap">
            <table class="normal mgT20" cellspacing="0">
              <tbody>
                <tr>
                  <th class="tblBg03">参加者</th>
                  <td v-for="member in eventMember">
                    {{ member.member.first_name }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <template v-for="member in eventMember">
            <div class="table-wrap option-color">
              <table class="normal mgT20" cellspacing="0">
                <tbody>
                  <tr>
                    <th colspan="2" class="tblBg03">{{ member.member.first_name }}の確認 / オプション</th>
                  </tr>
                  <template v-for="answer in answers">
                    <template v-if="answer.member_id == member.member.id">
                      <tr>
                        <th class="tblBg03">{{ answer.survey.content }}</th>
                        <td v-if="answer.choice">
                          {{ answer.choice.label }}
                        </td>
						<td v-else>
							{{ answer.text_answer }}
						</td>
                      </tr>
                    </template>
                  </template>
                  <tr>
                    <th class="tblBg03">{{ member.member.first_name }}さんオプション費用小計</th>
                    <template v-for="total in totals">
                      <td class="d-flex justify-content-end fw-bold" v-if="total.id == member.member.id"><span
                          class="subtotal">¥ {{ total.subtotal }}</span></td>
                    </template>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
          <div class="table-wrap total-color">
            <table class="normal mgT20" cellspacing="0">
              <tbody>
                <tr>
                  <th class="tblBg03">費用合計</th>
                  <td class="d-flex justify-content-end fw-bold" v-if="totals && event"><span class="total">¥ {{
                    event?.priceDetail * eventMember.length + parseInt(totals[0].total) }}</span></td>
                </tr>
              </tbody>
            </table>
          </div>
          <h4 class="mt-5">連絡事項</h4>
          <p class="mt-3" v-if="event?.messageAfter" v-html="event?.messageAfter.replace(/\n/g, '<br>')"></p>
          <div class="btn-area">
            <button class="btn btn-back" @click="$router.go(-1)">戻る</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SideBar from '@/components/Layouts/SideBar.vue'
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { TEvent, TEventMembership, TEventTarget, TEventSchedule, TPaymentMethod, TSurvey, TChoice, TAnswer, TRental } from '@/types/event'
import { TMember } from '@/types/member'
import { useMemberStore } from '@/stores/member'
import { toDateSlash } from '@/js/util'
import { handleErrors } from '@/config/handleErrors'

const router = useRouter()
const route = useRoute()
const memberStore = useMemberStore()

const event = ref<TEvent>()
const answers = ref<any>()
const eventMember = ref<any>()
const totals = ref<any>()
const schedules = ref<TEventSchedule>()

onMounted(async () => {
  try {
    const { event: fetchEvent,
      schedules: fetchSchedules,
      answers: fetchAnswers,
      eventMember: fetchEventMember
    }
      = await TEvent.fetchAttendEventMember({ id: Number(route.query.id), memberId: memberStore.id, code: String(route.query.code) })
    event.value = fetchEvent
    schedules.value = fetchSchedules
    answers.value = fetchAnswers
    eventMember.value = fetchEventMember
    totals.value = combineData(eventMember.value, answers.value)
  } catch (e: any) {
    handleErrors(e)
  }
})

function combineData(eventMember: any, data: any): { id: number; subtotal: number; total: number }[] {
  const combinedData: { id: number; subtotal: number; total: number }[] = [];
  let total = 0;
  eventMember.forEach(child => {
    let subtotal = 0;
    data.forEach(item => {
		if(item.choice){
	      total += item.choice.value;
			if (item.member_id === child.member.id) {
				subtotal += item.choice.value;
			}
		}
    })
    combinedData.push({
      id: child.member.id,
      subtotal: subtotal,
      total: total,
    });
  });
  return combinedData;
}

// const handleCancel = async() => {
//   const data = {
//     eventArg: event.value,
//     eventMemberArg: eventMember.value,
//     schedulesArg: schedules.value,
//     answersArg: answers.value,
//   }
//   console.log(data)
//   const result = await TEvent.cancel(data).then(
//     result => {
//       if (result === 'success') router.push({ name: 'MypageIndex'})
//     }
//   )
//   console.log(result)
// }

</script>
