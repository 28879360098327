export const SEASONS = [
  { id: 0, name: '指定なし' },
  { id: 1, name: '夏プログラム' },
  { id: 2, name: '冬プログラム' },
  { id: 3, name: '春プログラム' },
  // { id: 4, name: '秋プログラム' },
] as const

export const SEASONS_SHORT = [
{ id: 0, name: '指定なし' },
{ id: 1, name: '夏' },
{ id: 2, name: '冬' },
{ id: 3, name: '春' },
// { id: 4, name: '秋' },
] as const
