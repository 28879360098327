<template>
  <nav aria-label="breadcrumb" class="breadcrumb-area">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">マイページトップ</li>
    </ol>
  </nav>
  <div class="container-fluid">
    <div class="row mypage-contents">
      <side-bar />
      <div class="mypage-contents_contents ml-sm-auto px-md-4 py-4">
        <!-- <h1 class="h2">マイページトップ</h1> -->
        <div class="top--messageArea" v-if="notices && notices.length > 0">
          <h2>メンバー様へのお知らせ</h2>
          <div class="top--messageArea-list">
            <ul class="top--messageArea-list-inner">
              <template v-for="notice in notices" :key="notice.id">
                <li :class="isNew(notice.display_start_at) ? 'new-icon' : ''">
                  <router-link :to="{ name: 'NoticeDetail', params: { path: notice.path } }">
                    <div class="top--messageArea-list-item">
                      <span class="date" v-if="notice.display_start_at">{{ toDateSlash(notice.display_start_at)
                        }}</span>
                      <span class="text">{{ notice.title }}</span>
                    </div>
                  </router-link>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <h1 class="h2">参加予定プログラム</h1>
        <div class="top--section latestWorks mypage-top mb-3">
          <template v-for="(schedule, index) in allSchedules" :key="schedule.event_id">
            <div class="latestWorks-inner result-type"
              v-if="index < 6  && schedule.event && schedule.event.images && schedule.event.images.length > 0">
              <img v-if="schedule.event.images && schedule.event.images.length > 0" :src="schedule.event.images[0].url"
                width="100%">
              <img v-else src="@assets/images/blank.gif" width="100%" />
              <div class="latestWorks-inner--textarea">
                <div class="companyName">
                  <template v-if="!(isWaiting(schedule.event_member) == 2)">
                    <span class="badge bg-danger" v-if="isPaid(schedule.event_member) == 0">未入金</span>
                    <span class="badge bg-primary" v-else-if="isPaid(schedule.event_member) == 1">未入金あり</span>
                    <span v-else class="badge bg-primary">入金確認済</span>
                  </template>
                  <span class="badge bg-primary" v-if="isWaiting(schedule.event_member) == 0">申込済</span>
                  <span class="badge bg-primary" v-else-if="isWaiting(schedule.event_member) == 1">キャンセル待ちあり</span>
                  <span v-else class="badge bg-danger">キャンセル待ち</span>
                  <span class="badge bg-primary" v-if="schedule.event.price_detail > 0">有料</span><span
                    class="badge bg-danger" v-else>無料</span>
                </div>
                <div class="workTitle">
                  <router-link :to="{ name: 'EventDetail', params: { path: schedule.event.path } }">{{
                    schedule.event.title
                  }}</router-link>
                </div>
                <ul class="description">
                  <li v-if="schedule.event.target_types"><img src="@/images/icon-user-black2.svg" alt="対象">{{
          schedule.event.target_types[0]['name'] }}{{ schedule.event.target_types.length >= 2
          ? '〜' + schedule.event.target_types[schedule.event.target_types.length - 1]['name'] : '' }}</li>
                  <li><img src="@/images/icon-salary-black.svg" alt="給料">{{ schedule.event.price_detail }}(税込)</li>
                  <li v-if="schedule">
                    <img src="@/images/icon-star-black2.svg" alt="開催期間">
                    <template v-if="schedule.start_at === schedule.end_at">
                      {{ new Date(schedule.start_at).toLocaleDateString() }}
                    </template>
                    <template v-else>
                      {{ new Date(schedule.start_at).toLocaleDateString() }}{{ schedule.end_at ? '〜' + new
          Date(schedule.end_at).toLocaleDateString() : '' }}
                    </template>
                  </li>
                </ul>
                <div class="btn-area mb-0">
                  <router-link v-if="schedule && isAfter(schedule.end_at)"
                    :to="{ path: '/mypage/event_detail', query: { id: schedule.event.id, code: schedule.event_code } }"
                    class="btn btn-apply">詳細</router-link>
                  <router-link v-else
                    :to="{ path: '/mypage/event_detail_after', query: { id: schedule.event.id, code: schedule.event_code } }"
                    class="btn btn-apply">詳細</router-link>
                </div>
              </div>
            </div>
            <div class="latestWorks-inner result-type no-image" v-else-if="index < 6 && schedule.event">
              <img src="@assets/images/blank.gif" width="100%" />
              <div class="latestWorks-inner--textarea">
                <div class="companyName">
                  <template v-if="!(isWaiting(schedule.event_member) == 2)">
                    <span class="badge bg-danger" v-if="isPaid(schedule.event_member) == 0">未入金</span>
                    <span class="badge bg-primary" v-else-if="isPaid(schedule.event_member) == 1">未入金あり</span>
                    <span v-else class="badge bg-primary">入金確認済み</span>
                  </template>
                  <span class="badge bg-primary" v-if="isWaiting(schedule.event_member) == 0">申込済</span>
                  <span class="badge bg-primary" v-else-if="isWaiting(schedule.event_member) == 1">キャンセル待ちあり</span>
                  <span v-else class="badge bg-danger">キャンセル待ち</span>
                  <span class="badge bg-primary" v-if="schedule.event.price_detail > 0">有料</span><span
                    class="badge bg-danger" v-else>無料</span>
                </div>
                <div class="workTitle">
                  <router-link :to="{ name: 'EventDetail', params: { path: schedule.event.path } }">{{
          schedule.event.title
        }}</router-link>
                </div>
                <ul class="description">
                  <li v-if="schedule.event.target_types"><img src="@/images/icon-user-black2.svg" alt="対象">{{
          schedule.event.target_types[0]['name'] }}{{ schedule.event.target_types.length >= 2
          ? '〜' + schedule.event.target_types[schedule.event.target_types.length - 1]['name'] : '' }}</li>
                  <li><img src="@/images/icon-salary-black.svg" alt="給料">{{ schedule.event.price_detail }}(税込)</li>
                  <li v-if="schedule"><img src="@/images/icon-star-black2.svg" alt="開催期間">
                    <template v-if="schedule.start_at === schedule.end_at">
                      {{ new Date(schedule.start_at).toLocaleDateString() }}
                    </template>
                    <template v-else>
                      {{ new Date(schedule.start_at).toLocaleDateString() }}{{ schedule.end_at ? '〜' + new
          Date(schedule.end_at).toLocaleDateString() : '' }}
                    </template>
                  </li>
                </ul>
                <div class="btn-area mb-0">
                  <router-link v-if="schedule && isAfter(schedule.end_at)"
                    :to="{ path: '/mypage/event_detail', query: { id: schedule.event.id, code:schedule.event_code } }"
                    class="btn btn-apply">詳細</router-link>
                  <router-link v-else
                    :to="{ path: '/mypage/event_detail_after', query: { id: schedule.event.id, code:schedule.event_code } }"
                    class="btn btn-apply">詳細</router-link>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="btn-area mb-0" v-if="allSchedules.length > 6">
          <router-link class="btn btn-apply" to="/mypage/schedule">もっと見る</router-link>
        </div>

      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SideBar from '@/components/Layouts/SideBar.vue'
import { API } from '../../api'
// import { setActivePinia } from 'pinia'
import { handleError, onMounted, ref } from 'vue'
import { stringToDateSlash } from '@/js/util'
import { useMemberStore } from '@/stores/member';
import { handleErrors } from '@/config/handleErrors';
import { useLoadingStore } from '@/stores/loading'
import { toDateSlash } from '@/js/util'
import { TEvent, TEventMembership, TEventSchedule, TEventTarget } from '@/types/event'


const allSchedules = ref<any>([])
const favoriteEvents = ref<TEvent[]>([])
const notices = ref<any>([])
const memberStore = useMemberStore()

const now = ref(new Date())

onMounted(async () => {
  try {
    await API.post('/api/mypage/index', { id: memberStore.id }).then((r) => {
      notices.value = r.data.notices
      allSchedules.value = r.data.allSchedules
      favoriteEvents.value = (r.data.favoriteEvents as any[]).map(data => new TEvent(data['event']))
      now.value = r.data.now
      useLoadingStore().stop()
    }).catch((error) => {
      useLoadingStore().stop()
      handleErrors(error)
    })
  } catch (e: any) {
    handleErrors(e)
  }
})

function isWaiting(eventMember: any[]): number {
  let count = 0;
  eventMember.map(data => {
    if (data.is_waiting) {
      count++
    }
  })
  if (count == eventMember.length) {
    return 2
  } else if (count >= 1) {
    return 1
  }
  return 0
}

function isPaid(eventMember: any[]): number {
  let waitingCount = 0;
  let fixedCount = 0;
  eventMember.map(data => {
    if (data.is_waiting) {
      waitingCount++
    }
    if (data.fixed_at) {
      fixedCount++
    }
  })
  if (fixedCount == (eventMember.length - waitingCount)) {
    return 2
  } else if (fixedCount >= 1) {
    return 1
  }
  return 0
}

function isAfter(endAt: any): boolean {
  let isAfter = true
  const after = new Date(endAt)
  // after.setDate(date.getDate() + 7)
  if (after < now.value) {
    isAfter = false
  }
  return isAfter
}

const isNew = (day: string): boolean => {
  const startDay = new Date(day)
  const today = new Date()
  const beforeWeek = new Date(today.setDate(today.getDate() - 7))
  return startDay >= beforeWeek
}

</script>
