<template>
<nav aria-label="breadcrumb" class="breadcrumb-area">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
	<li class="breadcrumb-item"><router-link to="/mypage/index">マイページ</router-link></li>
    <li class="breadcrumb-item active" aria-current="page">参加予定プログラム確認</li>
  </ol>
</nav>
	<div class="container-fluid">
		<div class="row mypage-contents">
			<side-bar/>
			<div class="mypage-contents_contents ml-sm-auto px-md-4 py-4">
				<h2>参加予定プログラム確認</h2>
				<div class="entry-form-inner">
					<h4>申込み内容</h4>
					<div class="table-wrap">
						<table class="normal mgT20" cellspacing="0">
						<tbody><tr>
							<th class="tblBg03 tblTit">プログラムコード</th>
							<td v-if="schedules">{{ schedules.eventCode }}</td>
						</tr>
						<tr>
							<th class="tblBg03">プログラム名</th>
							<td>{{ event?.title}}</td>
						</tr>
						<tr>
							<th class="tblBg03">クラス開始日</th>
							<td v-if="schedules">{{ toDateSlash(schedules.startAt) }}</td>
						</tr>
						<tr>
							<th class="tblBg03">基本費用</th>
							<td>{{ event?.priceDetail }}円</td>
						</tr>
						</tbody></table>
					</div>
					<div class="table-wrap">
						<table class="normal mgT20" cellspacing="0">
							<tbody>
								<tr>
									<th class="tblBg03">参加者</th>
									<td v-for="member in eventMember">	
											{{ member.member.first_name }} さん<template v-if="member.is_waiting">(キャンセル待ち)</template>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<template v-if="isDisplay">
						<template v-for="member in eventMember">
							<div class="table-wrap option-color">
								<table class="normal mgT20" cellspacing="0" >
									<tbody>
										<tr>
											<th colspan="2" class="tblBg03">{{ member.member.first_name }}さんの確認 / オプション</th>
										</tr>
										<template v-if="answers" v-for="answer in answers">
											<template v-if="answer.member_id == member.member.id">
											<tr>
												<th class="tblBg03">{{ answer.survey.content }}</th>
												<td v-if="answer.choice">	
													{{ answer.choice.label }}
												</td>
												<td v-else>	
													{{ answer.text_answer }}
												</td>
											</tr>
											</template>
										</template>
										<tr>
											<th class="tblBg03">{{ member.member.first_name }}さんオプション費用小計</th>
											<template v-for="total in totals">
												<td class="d-flex justify-content-end fw-bold"  v-if="total.id == member.member.id "><span class="subtotal">¥ {{ total.subtotal }}</span></td>
											</template>
										</tr>
									</tbody>
								</table>
							</div>
						</template>
						<div class="table-wrap total-color">
							<table class="normal mgT20" cellspacing="0">
							<tbody>
								<tr>
									<th class="tblBg03">費用合計</th>
									<td class="d-flex justify-content-end fw-bold" v-if="totals && event"><span class="total">¥ {{ event?.priceDetail * eventMember.length + parseInt(totals[0].total) }}</span></td>
								</tr>
							</tbody>
							</table>
						</div>
						<h4 class="mt-5">当日の持ち物</h4>
						<p class="mt-3"></p>
						<template v-for="member in eventMember">
							<div class="table-wrap option-color">
								<table class="normal mgT20" cellspacing="0">
								<tbody>
									<tr>
										<th class="tblBg03">{{ member.member.first_name }}さんの持ち物</th>
									</tr>
									<tr>
										<td v-if="event?.item" v-html="event?.item.replace(/\n/g, '<br>')">	
										</td>
									</tr>
									</tbody>
								</table>
							</div>
						</template>
						<h4 class="mt-5">連絡事項</h4>
						<p class="mt-3" v-if="event?.messageBefore" v-html="event?.messageBefore.replace(/\n/g, '<br>')"></p>
					</template>
					<div class="event-info tab-content">
						<h4 class="mt-5">プログラム詳細</h4>
						<div class="requirements-contents tab-pane active guideline-item">
							<div>
								<h6>種類</h6>
								<p class="ps-2" v-if="event?.kindType">
									{{ event?.kindType.name }}
								</p>
							</div>
							<div class="mt-4">
								<h6>拠点</h6>
								<p class="ps-2" v-if="event?.base">
									<a :href="event?.base.url" target="_blank" v-if="event?.base">{{ event?.base.name }}</a>
								</p>
							</div>
							<div class="mt-4">
								<h6>カテゴリー</h6>
								<p class="ps-2" v-if="event?.categoryType">
									{{ event?.categoryType.name }}
								</p>
							</div>
							<div class="mt-4">
							<h6>対象</h6>
								<p class="ps-2">
									{{ minTarget?.name }} ～ {{ maxTarget?.name }}
								</p>
							</div>
							<div class="mt-4">
							<h6>申込先YMCA</h6>
								<p class="ps-2" v-if="event?.department">
									{{ event.department.name }}
								</p>
							</div>
							<div class="mt-4">
								<h6>定員</h6>
								<p class="ps-2">
									{{ event?.displayCapacity }}
								</p>
							</div>
							<div class="mt-4">
							<h6>料金</h6>
								<p class="ps-2" v-if="event?.priceDetail">
									{{ event?.priceDetail.toLocaleString() }}円
								</p>
								<p class="ps-2" v-else>
									0円
								</p>
							</div>
							<div class="mt-4">
								<h6>開催期間</h6>
                  <template v-if="schedules?.startAt && schedules?.endAt && schedules?.startAt === schedules?.endAt">
                    {{ toDateSlash(schedules?.startAt) }}
                  </template>
                  <template v-else>
                    {{ toDateSlash(schedules?.startAt) }} ～ {{ toDateSlash(schedules?.endAt)  }}
                  </template>
								</div>
							<div class="mt-4">
								<h6>開催地/行き先</h6>
								<p class="ps-2" v-if="event?.destination">
									{{ event?.destination }}
								</p>
							</div>
							<div class="mt-4">
								<h6>集合解散</h6>
								<p class="ps-2" v-if="event?.gatheringDisbanding">
									{{ event?.gatheringDisbanding }}
								</p>
							</div>
							<div class="mt-4">
								<h6>詳細</h6>
								<p class="ps-2" v-if="event?.content">
									<!-- 【詳細】<br/> -->
									<p v-html="event?.content.replace(/\n/g, '<br>')">
									</p>
								</p>
								<p class="ps-2">
									<!-- 【日　程】<br v-if="eventSchedules && eventSchedules.length > 1"/>
									<template v-for="schedule in eventSchedules" :key=schedule.id>
										{{ toDateJap(schedule.startAt) }}({{ getDayOfWeek(schedule.startAt) }})～{{ toDateJap(schedule.endAt) }}({{ getDayOfWeek(schedule.endAt) }}) {{stays(schedule.startAt, schedule.endAt)}}泊{{stays(schedule.startAt, schedule.endAt) + 1}}日<br />
									</template> -->
											<br />
                  <template v-if="event?.includedInPrice">《参加費に含まれるもの》<br />
                    <p class="ps-2" v-html="event?.includedInPrice.replace(/\n/g, '<br>')"></p><br /></template>
                  <template v-if="event?.excludedInPrice">《参加費に含まれないもの》<br />
                    <p class="ps-2" v-html="event?.excludedInPrice.replace(/\n/g, '<br>')"></p><br /></template>
									<template v-if="event?.link">【ホームページ】<br/>
									<a :href="event?.link" target="_blank">{{ event?.link }}</a><br/></template>
								</p>
								<p class="ps-2" v-if="event?.manager">
									【担　当】{{ event?.manager }}<br />
								</p>
							<!-- <p class="ps-2" v-html="event?.content.replace(/\n/g, '<br>')"></p> -->
								<p class="ps-2" v-if="event?.other" v-html="event?.other.replace(/\n/g, '<br>')"></p>
								<table class="table" v-if="event?.timeTables && event?.timeTables.length > 0">
									<thead>
									<tr>
										<th v-for="(col, index) in event.timeTableCols " :key="col">
										<p v-html="event.timeTables[0][`col${index + 1}`]"></p>
										</th>
									</tr>
									</thead>
									<tbody>
									<tr v-for="row in event.timeTables.slice(1)" :key="row.id">
										<td v-for="(col, index) in event.timeTableCols " :key="col">
										<p v-html="row[`col${index + 1}`].replace(/\n/g, '<br/>')"></p>
										</td>
									</tr>
									</tbody>
								</table>
								<p class="ps-2" v-if="event?.sideNote" v-html="event?.sideNote.replace(/\n/g, '<br>')"></p>
							</div>
							<div class="mt-4" v-if="event?.department">
								<h6>問い合わせ</h6>
								<p class="ps-2" v-if="event.department">{{ event.department.name }}</p>
								<p class="ps-2" v-if="event.department">メールアドレス：<a href="mailto:{{ event.department.mailAddress }}">{{ event.department.mailAddress }}</a></p>
								<p class="ps-2" v-if="event.department">電話番号：<a href="tel:{{ event.department.phoneNumber }}">{{ event.department.phoneNumber }}</a></p>
								<p class="ps-2" v-if="event.department.reception">受付時間：{{ event.department.reception }}</p>
								<!-- <p class="ps-2"><a :href="event?.department.url" target="_blank">{{ event?.department.url }}</a></p> -->
							</div>
						</div>
					</div>
					<div class="btn-area">
						<button class="btn btn-back" @click="$router.go(-1)">戻る</button>
						<!-- 七日以内を入れる -->
						<button class="btn btn-primary" v-if="cancel"  data-toggle="modal"
                                data-target="#select-modal-1">キャンセルをする</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- modal -->
	<!-- <div class="modal fade modal-family" id="select-modal-1" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
    style="display: none;">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
			<p></p>
			<p v-for="member in eventMember"><input type="checkbox" v-model="member.is_cancel">{{ member.member.first_name }}</p>
		</div>
        <div class="modal-footer">
          <div class="row w-100 justify-content-end">
            <div class="col-md-4 text-right">
              <button type="button" class="btn btn-back" data-dismiss="modal">キャンセルしない</button>
            </div>
            <div class="col-md-4 mt-2 mt-md-0">
              <button class="btn btn-primary" data-dismiss="modal" @click="handleCancel" >キャンセルする</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="modal fade" id="select-modal-1" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
    style="display: none;">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-body">
				<p class="modal-title h6 mb-2">キャンセルする方を選んでください</p>
				<div class="d-flex align-items-center" v-for="(member, index) in eventMember">
					<input type="checkbox" class="form-check-input me-2" :name="`check-type${index}`" :id="`check-${index}`" v-if="member.show_cancel" v-model="member.is_cancel">
					<label class="mb-0 ml-1" :for="`check-${index}`" v-if="member.show_cancel">{{ member.member.first_name }}</label><template v-if="member.is_waiting">(キャンセル待ち)</template>
				</div>
			</div>
			<div class="modal-footer">
				<div class="btn-area my-0">
					<button	type="button" class="btn btn-back" data-dismiss="modal">キャンセルしない</button>
					<button	type="button" class="btn btn-primary" data-dismiss="modal" @click="handleCancel">キャンセルする</button>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script setup lang="ts">
import SideBar from '@/components/Layouts/SideBar.vue'
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { TEvent, TEventMembership, TEventTarget, TEventSchedule, TPaymentMethod, TSurvey, TChoice, TAnswer, TRental } from '@/types/event'
import { TMember } from '@/types/member'
import { useMemberStore } from '@/stores/member'
import { useLoadingStore } from '@/stores/loading'
import { toDateSlash } from '@/js/util'
import { handleErrors } from '@/config/handleErrors'

const router = useRouter()
const route = useRoute()
const memberStore = useMemberStore()

const event = ref<TEvent>()
const answers = ref<any>()
const eventMember = ref<any>()
const totals = ref<any>()
const cancel = ref<boolean>()
const isDisplay = ref<boolean>()
const schedules = ref<TEventSchedule>()
const minTarget = ref<TEventTarget>()
const maxTarget = ref<TEventTarget>()
const eventTargets = ref<TEventTarget[]>()


onMounted(async () => {
  try {
    const { event: fetchEvent, 
        schedules: fetchSchedules, 
        answers: fetchAnswers,
        eventMember: fetchEventMember,
        eventTargets: fetchEventTargets,
        } 
      = await TEvent.fetchAttendEventMember({ id: Number(route.query.id), memberId: memberStore.id, code: String (route.query.code)})
    event.value = fetchEvent
    schedules.value = fetchSchedules
    answers.value = fetchAnswers
    eventMember.value = fetchEventMember
    totals.value = combineData(eventMember.value, answers.value)
    cancel.value = isCancel(eventMember.value)
	isDisplay.value = isApp(eventMember.value)
	eventTargets.value = fetchEventTargets

	minTarget.value = eventTargets.value.reduce((min: TEventTarget, current: TEventTarget) => {
        return min.id < current.id ? min : current;
      })
      maxTarget.value = eventTargets.value.reduce((max, current) => {
        return max.id > current.id ? max : current;
      }) 
  } catch(e: any) {
    handleErrors(e)
  }
})

const formattedTime = (time: string): string => {
  if(time){
    const [hours, minutes] = time.split(':');
    return `${hours}:${minutes}`;
  }
  return ''
};

function combineData(eventMember: any, data: any): { id: number; subtotal:number; total: number }[] {
  const combinedData: { id: number; subtotal: number;total: number }[] = [];
  let total = 0;
  eventMember.forEach(member => {
    let subtotal = 0;
    data.forEach(item => {
		if (item.choice && !('text_answer' in item.choice)){
			total += item.choice.value;
			if (item.member_id === member.member.id) {
				subtotal += item.choice.value;
			}
		}
    })
    combinedData.push({
      id: member.member.id,
      subtotal: subtotal,
	  total: total,
    });
  });
  return combinedData;
}

function isCancel(eventMember: any): boolean {
  let cancel = true
  let count = 0
  eventMember.forEach(member => {
	const date = new Date(member.reserved_at? member.reserved_at: member.created_at)
	let after = new Date(date)
	if(event.value?.cancelType != 2){
		after.setDate(date.getDate() + event.value?.daysUntilCancel!)
		// ↑No350これを削除してNo350↓コメント解除
		// if(event.value?.daysUntilCancel! >= 1){
		// 	after.setDate(date.getDate() + event.value?.daysUntilCancel! -1)
		// }else{
		// 	after.setDate(date.getDate() + event.value?.daysUntilCancel!)
		// }
	}else{
		after = new Date(event.value?.cancellationDeadline)
	}
	const now = event.value?.serverNow ?? new Date()
	if(member.fixed_at || after < now){
		member.show_cancel = false
		count++
	}
  })
  if (count == eventMember.length) cancel = false
  return cancel
}

function isApp(eventMember: any): boolean {
  let flg = true
  let count = 0
  eventMember.forEach(member => {
	if(member.is_waiting){
		count++
	}
  })
  if (count == eventMember.length) flg = false
  return flg
}

const handleCancel = async() => {
	useLoadingStore().start()
	const cancelMembers:any[] = []
	const cancelAnswers:any[] = []
	for(const member of eventMember.value){
		if(member.is_cancel){
			cancelMembers.push(member)
			for(const answer of answers.value){
				if(answer.member_id == member.member_id){
					cancelAnswers.push(answer)
				}
			}
		}
	}
  const data = {
    eventArg: event.value,
    eventMemberArg: cancelMembers,
    schedulesArg: schedules.value,
    answersArg: cancelAnswers,
  }
  if(cancelMembers.length >0 ){
	try {
		const result = await TEvent.cancel(data).then(
		result => {
			if (result === 'success') router.push({ name: 'MypageIndex'})
		})
	} catch(e: any) {
		handleErrors(e)
	} finally{
		useLoadingStore().stop()
	}
  }else{
	alert('キャンセルする名前を１人以上選択してください');
	useLoadingStore().stop()
  }
}

</script>

