<template>
  <nav aria-label="breadcrumb" class="breadcrumb-area">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
      <li class="breadcrumb-item"><router-link to="/search/index">プログラム検索</router-link></li>
      <li class="breadcrumb-item" v-if="event"><router-link
          :to="{ name: 'EventDetail', params: { path: event?.path } }">プログラム詳細</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">プログラム予約申込</li>
    </ol>
  </nav>
  <div class="container" v-if="event?.isDisplay">
    <h2 v-if="isAllApplicable">プログラム予約申込</h2>
    <h2 v-else>キャンセル待ちプログラム予約申込</h2>
    <div class="entry-form-inner">
      <p class="mt-3">プログラム名、料金をご確認の上、確認ボタンをクリックしてください。</p>
      <div class="table-wrap">
        <table class="normal mgT20" cellspacing="0">
          <tbody>
            <tr>
              <th class="tblBg03 tblTit">プログラムコード</th>
              <td>{{ event?.id }}</td>
            </tr>
            <tr>
              <th class="tblBg03">プログラム名</th>
              <td>{{ event?.title }}</td>
            </tr>
            <tr>
              <th class="tblBg03">開催日</th>
              <td>
                <template v-for="schedule in eventSchedules" :key="schedule.id">
                  <div class="row m-0">
                    <div class="form-check">
                      <template v-if="schedule.startAt && schedule.endAt && schedule.startAt === schedule.endAt">
                        <input type="checkbox" class="form-check-input" :id="`schedule-${schedule.id}`"
                        v-model="schedule.isChecked" :disabled="displayReceptionStatus(event?.participants.find(p => p.eventCode === schedule.eventCode)) === '(満席)'">
                        <label class="form-check-label" :for="`schedule-${schedule.id}`">{{ toDateSlash(schedule.startAt) }} </label>
                        <label class="form-check-label" v-if="!isWaiting(schedule)">{{ displayReceptionStatus(event?.participants.find(p => p.eventCode === schedule.eventCode)) }}</label>
                      </template>
                      <template v-else-if="schedule.startAt && schedule.endAt">
                        <input type="checkbox" class="form-check-input" :id="`schedule-${schedule.id}`"
                        v-model="schedule.isChecked" :disabled="displayReceptionStatus(event?.participants.find(p => p.eventCode === schedule.eventCode)) === '(満席)'">
                        <label class="form-check-label" :for="`schedule-${schedule.id}`" >{{ toDateSlash(schedule.startAt) }}{{ schedule.endAt ? '〜' + toDateSlash(schedule.endAt) : '' }}  </label>
                        <label class="form-check-label" v-if="!isWaiting(schedule)">{{ displayReceptionStatus(event?.participants.find(p => p.eventCode === schedule.eventCode)) }}</label>
                      </template>
                    </div>
                  </div>
                </template>
                <!-- <div class="row m-0">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="class-start_20240115">
                    <label class="form-check-label" for="class-start_20240115">2024/01/14(キャンセル待ち)</label>
                  </div>
                </div>
                <div class="row row-cols-1 row-cols-sm-2 m-0">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="class-start_20240115">
                    <label class="form-check-label" for="class-start_20240115">2024/01/15</label>
                  </div>
                </div>
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="class-start_20240122">
                  <label class="form-check-label" for="class-start_20240122">2024/01/22〜25</label>
                </div> -->
              </td>
            </tr>
            <tr>
              <th class="tblBg03">支払い方法</th>
              <td>
                <select name="select-payment" class="form-control form-select" v-model="paymentId">
                  <option v-for="pay in paymentMethods" :key="pay.id" :value="pay.id">{{ pay.name }}</option>
                </select>
              </td>
            </tr>
            <tr>
              <th class="tblBg03">基本費用</th>
              <td v-if="event?.priceDetail">
                {{ event?.priceDetail.toLocaleString() }}{{ event?.priceDetail ? '円' : '' }}
              </td>
              <td v-else>
                0円
              </td>
            </tr>
            <!-- <tr v-if="!isAllApplicable">
              <th class="tblBg03">「キャンセル規定・注意事項」を確認した</th>
              <td>
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="cancelDoui" v-model="isCancelAgree">
                  <label for="cancelDoui" class="form-check-label">
                    確認しました
                  </label>
                </div>
              </td>
            </tr> -->
            <!-- <tr>
              <th class="tblBg03">同意書に捺印して頂く必要があります。</th>
              <td>
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="doui" v-model="isAgree">
                  <label for="doui" class="form-check-label">
                    了承しました
                  </label>
                </div>
              </td>
            </tr> -->
          </tbody>
        </table>
      </div>
      <div class="table-wrap" v-if="event?.applicantParent && parent">
        <table class="normal mgT20" cellspacing="0">
          <tbody>
            <tr>
              <th class="tblBg03">本人</th>
              <td>
                <div class="row row-cols-1 row-cols-sm-2 m-0">
                  <div class="form-check" v-if="isParticipatable(parent)">
                    <input type="checkbox" class="form-check-input" :id="`parent-${parent.id}`" :value="true" v-model="parent.willAttend">
                    <label class="form-check-label" :for="`parent-${parent.id}`">{{ parent.firstName }}</label>
                  </div>
                  <div class="form-check" v-else>
                    <input type="checkbox" class="form-check-input" :id="`parent-${parent.id}`" :value="true" v-model="parent.willAttend" disabled>
                    <label class="form-check-label" :for="`parent-${parent.id}`"><span class="text-secondary">{{ parent.firstName }}(年齢対象外)</span></label>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <template v-if="event?.applicantChild && children && children?.length > 0">
        <div class="table-wrap">
          <table class="normal mgT20" cellspacing="0">
            <tbody>
              <tr>
                <th class="tblBg03">参加者選択</th>
                <td>
                  <div class="row row-cols-1 row-cols-sm-2 m-0">
                    <template v-for="rental in rentals" :key="rental.member.id">
                      <template v-if="rental.memberId !== parent?.id">
                        <div class="form-check" v-if="isParticipatable(rental.member)">
                          <input type="checkbox" class="form-check-input" :id="`child-${rental.member.id}`" :value="true"
                            v-model="rental.member.willAttend">
                          <label class="form-check-label" :for="`child-${rental.member.id}`">{{ rental.member.firstName
                            }}</label>
                        </div>
                        <div class="form-check" v-else>
                          <input type="checkbox" class="form-check-input" :id="`child-${rental.member.id}`" :value="true"
                            v-model="rental.member.willAttend" disabled>
                          <label class="form-check-label" :for="`child-${rental.member.id}`"><span class="text-secondary">{{
            rental.member.firstName }}(年齢対象外)</span></label>
                        </div>
                      </template>
                    </template>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    </template>
      <!-- <div class="table-wrap option-color" v-for="child in children" :key="child.id"> -->
      <template v-for="rental in rentals" :key="rental.id">
        <div class="table-wrap option-color" v-if="rental.member.willAttend">
          <table class="normal mgT20" cellspacing="0">
            <tbody>
              <tr>
                <th colspan="2" class="tblBg03">{{ rental.member.firstName }}さんの確認 / オプション</th>
              </tr>
              <template v-for="survey in surveys" :key="survey.id">
                <tr v-if="survey.type">
                  <th class="tblBg03">{{ survey.content }}</th>
                  <td>
                    <div v-if="survey.type === 1">
                      <check-component v-model:options="survey.choices" v-model:survey-id="survey.id"
                        v-model:choices="rental.choices" v-model:answers="rental.answers"
                        :memberId="rental.member.id"></check-component>
                    </div>
                    <div v-if="survey.type === 2">
                      <radio-component v-model:options="survey.choices" v-model:survey-id="survey.id"
                        v-model:choices="rental.choices" v-model:answers="rental.answers"
                        :memberId="rental.member.id"></radio-component>
                    </div>
                    <div v-if="survey.type === 3">
                      <select-component v-model:options="survey.choices" v-model:survey-id="survey.id"
                        v-model:choices="rental.choices" v-model:answers="rental.answers"
                        :memberId="rental.member.id"></select-component>
                    </div>
                    <div v-if="survey.type === 4">
                      <text-component v-model:options="survey.choices" v-model:survey-id="survey.id"
                        v-model:choices="rental.choices" v-model:answers="rental.answers"
                        :memberId="rental.member.id"></text-component>
                    </div>
                  </td>
                </tr>
              </template>
              <!-- <tr>
                <th class="tblBg03">レンタルウェアの希望</th>
                <td>
                  <select name="option3" class="form-control form-select">
                    <option value="▼をクリックしてお選びください">▼をクリックしてお選びください</option>
                    <option value="レンタルを希望しない">レンタルを希望しない</option>
                    <option value="レンタルウェアを希望（+６，８２０円）">レンタルウェアを希望（+６，８２０円）</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th class="tblBg03">レンタルヘルメット</th>
                <td>
                  <select name="option4" class="form-control form-select">
                    <option value="▼をクリックしてお選びください">▼をクリックしてお選びください</option>
                    <option value="レンタルを希望しない">レンタルを希望しない</option>
                    <option value="レンタルヘルメットを希望（+１，６５０円）">レンタルヘルメットを希望（+１，６５０円）</option>
                  </select>
                </td>
              </tr> -->
              <tr>
                <th class="tblBg03">{{ rental.member.firstName }}さんのオプション費用小計</th>
                <td class="d-flex justify-content-end fw-bold"><span class="subtotal">¥ {{ rental.getTotalChoicesValue()
                    }}</span>
                </td>
                <!-- <td class="d-flex justify-content-end fw-bold"><span class="subtotal">¥ {{ calcTotal(choices) }}</span></td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <div class="table-wrap total-color">
        <table class="normal mgT20" cellspacing="0">
          <tbody>
            <tr>
              <th class="tblBg03">費用合計</th>
              <td class="d-flex justify-content-end fw-bold"><span class="total">¥ {{ calcTotal.toLocaleString()
                  }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="btn-area">
        <button class="btn btn-back" @click="$router.go(-1)">検索結果へ戻る</button>
        <button type="button" class="btn btn-apply" @click="toConfirm()" :disabled="!isDisabled">確認</button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { RouteParamsRaw, useRoute, useRouter } from 'vue-router'
import { TEvent, TEventMembership, TEventTarget, TEventSchedule, TPaymentMethod, TSurvey, TChoice, TAnswer, TRental } from '@/types/event'
import { useMemberStore } from '@/stores/member'
import { useEventStore } from '@/stores/event'
import { TMember } from '@/types/member'
import { toDateSlash, getGrade, displayReceptionStatus } from '@/js/util'
import SelectComponent from '@/components/Surveys/SelectComponent.vue'
import RadioComponent from '@/components/Surveys/RadioComponent.vue'
import TextComponent from '@/components/Surveys/TextComponent.vue'
import CheckComponent from '@/components/Surveys/CheckComponent.vue'
import { handleErrors } from '@/config/handleErrors'
import { SEO } from '@/config/seo'
import { useHead } from '@unhead/vue'
import { useLoadingStore } from '@/stores/loading'

const route = useRoute()
const router = useRouter()
const memberStore = useMemberStore()

const event = ref<TEvent>()
const eventTargets = ref<TEventTarget[]>()
const eventMemberships = ref<TEventMembership[]>()
const minTarget = ref<TEventTarget>()
const maxTarget = ref<TEventTarget>()
const eventSchedules = ref<TEventSchedule[]>()
const paymentMethods = ref<TPaymentMethod[]>()
const parent = ref<TMember>()
const children = ref<TMember[]>()
const surveys = ref<TSurvey[]>()

// const isAgree = ref<boolean>(false)
// const isCancelAgree = ref<boolean>(false)
const paymentId = ref<number>(0)
const choices = ref<TChoice[]>([])
const answers = ref<TAnswer[]>([])

const rentals = ref<TRental[]>([])

const seoTitle = ref<string>('')
watch(
  () => route.name,
  (newRouteName) => {
    if (newRouteName && SEO[newRouteName]) {
      useHead({
        title: () => seoTitle.value ? SEO[newRouteName].title.replace('プログラム申込', seoTitle.value): SEO[newRouteName].title,
        meta: [
          {
            name: 'description',
            content: SEO[newRouteName].description,
          },
          {
            name: 'keywords',
            content: SEO[newRouteName].keywords,
          },
        ],
      })
    }
  },
  { immediate: true }
)

onMounted(async () => {
  try {
    const { event: fetchEvent,
      parent: fetchParent,
      children: fetchChildren,
      schedules: fetchSchedules,
      paymentMethods: fetchPayments,
      eventTargets: fetcheventTargets,
      surveys: fetchSurvey,
    }
      = await TEvent.fetchEventMember({ id: Number(route.query.id), memberId: memberStore.id })
    event.value = fetchEvent
    parent.value = fetchParent
    children.value = fetchChildren
    eventSchedules.value = fetchSchedules
    eventTargets.value = fetcheventTargets
    paymentMethods.value = fetchPayments
    surveys.value = fetchSurvey
    if (event.value.applicantParent) {
      rentals.value.push(TRental.fromData(0, parent.value.id, parent.value, [], []))
    }
    for (const child of children.value) {
      rentals.value.push(TRental.fromData(0, child.id, child, [], []))
    }
    if (!event.value.isDisplay) {
      router.push({ name: 'Top' })
    }
    seoTitle.value = event.value.title
  } catch (e: any) {
    handleErrors(e)
  }
})

const isAllApplicable = computed(() => {
  for (const participant of event.value?.participants ? event.value?.participants : []) {
    if (participant.isApplicable) {
      return participant.isApplicable
    }
  }
  return false
})

function isParticipatable(child: TMember): boolean {
  const grade = getGrade(child.birthday)
  let isParticipatableGrade: number[] = []
  for (const target of eventTargets.value ? eventTargets.value : []) {
    switch (target.name) {
      case '0歳': isParticipatableGrade.push(1)
        break
      case '1歳': isParticipatableGrade.push(2)
        break
      case '2歳': isParticipatableGrade.push(3)
        break
      case '年少': isParticipatableGrade.push(4)
        break
      case '年中': isParticipatableGrade.push(5)
        break
      case '年長': isParticipatableGrade.push(6)
        break
      case '小学1年生': isParticipatableGrade.push(7)
        break
      case '小学2年生': isParticipatableGrade.push(8)
        break
      case '小学3年生': isParticipatableGrade.push(9)
        break
      case '小学4年生': isParticipatableGrade.push(10)
        break
      case '小学5年生': isParticipatableGrade.push(11)
        break
      case '小学6年生': isParticipatableGrade.push(12)
        break
      case '中学1年生': isParticipatableGrade.push(13)
        break
      case '中学2年生': isParticipatableGrade.push(14)
        break
      case '中学3年生': isParticipatableGrade.push(15)
        break
      case '高校1年生': isParticipatableGrade.push(16)
        break
      case '高校2年生': isParticipatableGrade.push(17)
        break
      case '高校3年生': isParticipatableGrade.push(18)
        break
      case '大学生・社会人': isParticipatableGrade.push(19)
        break
      case '成人': isParticipatableGrade.push(20)
        break
    }
  }
  if (isParticipatableGrade.includes(grade)) {
    return true
  } else {
    return false
  }
}

const calcTotal = computed(() => {
  let total = 0
  if (!event.value) return 0
  const members = rentals.value.filter(m => m.member.willAttend === true)
  if (members) total += (event.value?.priceDetail * members.length)
  for (const rental of rentals.value) {
    // if (event.value?.priceDetail && rental.member.willAttend) total += event.value?.priceDetail
    total += rental.getTotalChoicesValue()
  }
  let participateSchedules
  if (eventSchedules.value) {
    participateSchedules = eventSchedules.value?.filter(s => s.isChecked === true)
  }
  return total * (participateSchedules?.length ?? 0)
})

const isDisabled = computed(() => {
  if(!hissu.value){
    return false
  }
    return eventSchedules.value ? eventSchedules.value.some(schedule => schedule.isChecked === true) && paymentId.value && rentals.value.filter(m => m.member.willAttend).length > 0  : false
})

const isWaiting = (schedule: TEventSchedule) => {
  if (event.value) {
    const participant = event.value.participants.find(p => p.eventCode === schedule.eventCode)
    return participant ? participant.isApplicable : false
  } else {
    return false
  }
}

const hissu = computed(() => {
  let surveysIds = surveys.value?.map(survey => survey.id)
  const members = rentals.value.filter(m => m.member.willAttend === true)
  // console.log(rentals.value)
  for(const member of members){
    const answersSurveyIds = member.answers.map(answer => answer.surveyId)
    // console.log(answersSurveyIds)
    for(const id of surveysIds!) {
      const index = answersSurveyIds.findIndex(no => no === id)
      if(index == -1){
        return false
      }
    }
  }
  return true
})

const toConfirm = async() => {
  const data = {
    eventArg: event.value,
    parentArg: parent.value,
    childrenArg: children.value,
    schedulesArg: eventSchedules.value,
    paymentMethodsArg: paymentMethods.value,
    surveysArg: surveys.value,
    rentalsArg: rentals.value,
    paymentIdArg: paymentId.value
  }
  useLoadingStore().start()
  try {
    const result = await TEvent.check(data).then(
      result => {
        if (result === 'success') {
          useEventStore().setEventConfirm(data)
          router.push({ name: 'EventConfirm' })
        }
      }
    )
  } catch (e: any) {
    handleErrors(e)
  }
  
}

</script>